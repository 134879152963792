import React from "react";
import "../styles/whatsapp-button.css";
import {getParameterByName} from "../utils/formatter";

export default function ChatbasePopup(props) {
    const handleWhatsAppClick = () => {
        const businessId = getParameterByName("business");
        // Puedes personalizar estos enlaces con números de WhatsApp específicos
        const whatsappUrl = businessId === "W8fBSZcVw5" 
            ? "https://api.whatsapp.com/send/?phone=34618843753&text=Hola+Marta%2C+te+escribo+para+pedir+informaci%C3%B3n+sobre+la+creaci%C3%B3n+de+un+servicio"
            : "https://api.whatsapp.com/send/?phone=34618843753&text=Hola+Marta%2C+te+escribo+para+pedir+informaci%C3%B3n+sobre+la+creaci%C3%B3n+de+un+servicio";
            
        window.open(whatsappUrl, "_blank", "noopener,noreferrer");
    };

    return (
        <div className="whatsapp-sticky-button" onClick={handleWhatsAppClick}>
            <img
                src={require("../assets/whatsapp.png")}
                alt="WhatsApp" 
                width="60" 
                height="60" 
            />
        </div>
    );
}
