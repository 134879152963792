import React, { Component } from "react";
import Parse from "parse";
import Lottie from "react-lottie";
import Jobin, { Business } from "jobin-client";

import Nav from "../components/Nav";
import ServiceItem from "../components/ServiceItem";
import CustomServiceItem from "../components/CustomServiceItem";
import ServiceSteps from "../components/ServiceSteps";
import SearchService from "../components/SearchService";

import "../styles/service-type.css";
import * as cardSpinnerData from "../animations/card-spinner.json";
import { connect } from 'react-redux';
import { saveCodeJustNow } from '../store/actions/justNowActions';
import { isPelayo, isMutua } from '../store/actions/businessCodeReducer';
import { jobRouter, jobAvailability, jobDescription } from '../store/actions/jobAction';
import { jobAddress } from '../store/actions/serviceAction';
import {getURLParameterByName, getParameterByName} from "../utils/formatter";
import Ufesa_banner_horizontal from "../assets/Ufesa_banner_horizontal.png"
import Ufesa_banner_vertical from "../assets/Ufesa_banner_vertical.png"
import Create_banner_horizontal from "../assets/Create_banner_horizontal.png"
import Create_banner_vertical from "../assets/Create_banner_vertical.png"
import Diglo_banner_horizontal from "../assets/Diglo_banner_horizontal.png"
import Diglo_banner_vertical from "../assets/Diglo_banner_vertical.png"



class ServiceType extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            childrenLoaded: [],
            isChildrenLoaded: false,
            isIdealista: false,
            isCubro:false,
            isDiglo:false,
            isCreate:false,
            isUfesa:false,
            isMartha:false,
            isCiudadela: false,
            isBauhaus: false,
            isHousell: false,
            isWivai: false,
            isPelayo: false,
            isFacilitea: false,
            isAcentor: false,
            isProManager: false,
            isFintonic: false,
            isGesvalt: false,
            fintonicLoqued: ["abUWKFByHi","igh3uGimwW","NIyaZf2leK","qoPtKAP7ic","Dji0HFOQQU","yG8XlJhsxT","DZpfKGsk4f","vtzUSvaLV0","XjDO1qEH4P","4NpnRr2DL7"
                            ,"U9XQyScbgL","wrErlkbjuw","aKoILq1CeH","ZppXwC9wah","HpjTYgDnvp","C4WQVQgu9m","nMHyMTrgDP","lZdG7WGhke","xWgJs5Gw89","37kTMwNoTT",
                            "alzKp0U9Zb","ALgDH1dbXo"],
            gesvaltLoqued: ["abUWKFByHi","vgLHBzIOeu","igh3uGimwW","NIyaZf2leK","qoPtKAP7ic","Dji0HFOQQU","yG8XlJhsxT","DZpfKGsk4f","vtzUSvaLV0","XjDO1qEH4P","4NpnRr2DL7"
                            ,"U9XQyScbgL","wrErlkbjuw","aKoILq1CeH","ZppXwC9wah","HpjTYgDnvp","C4WQVQgu9m","nMHyMTrgDP","lZdG7WGhke","xWgJs5Gw89","37kTMwNoTT",
                            "alzKp0U9Zb","ALgDH1dbXo"],
            marthaLoqued: ["abUWKFByHi","vgLHBzIOeu","igh3uGimwW","NIyaZf2leK","qoPtKAP7ic","Dji0HFOQQU","yG8XlJhsxT","DZpfKGsk4f","vtzUSvaLV0","XjDO1qEH4P","4NpnRr2DL7"
                            ,"U9XQyScbgL","wrErlkbjuw","aKoILq1CeH","ZppXwC9wah","HpjTYgDnvp","C4WQVQgu9m","nMHyMTrgDP","lZdG7WGhke","xWgJs5Gw89","37kTMwNoTT",
                            "alzKp0U9Zb","ALgDH1dbXo","F2O62Uf1ki","OJTfDov974","oP4RL8XHva"],
            digloLoqued: ["abUWKFByHi","vgLHBzIOeu","igh3uGimwW","NIyaZf2leK","qoPtKAP7ic","Dji0HFOQQU","yG8XlJhsxT","DZpfKGsk4f","vtzUSvaLV0","XjDO1qEH4P","4NpnRr2DL7"
                            ,"U9XQyScbgL","wrErlkbjuw","aKoILq1CeH","ZppXwC9wah","HpjTYgDnvp","C4WQVQgu9m","nMHyMTrgDP","lZdG7WGhke","xWgJs5Gw89","37kTMwNoTT",
                            "alzKp0U9Zb","ALgDH1dbXo","F2O62Uf1ki","OJTfDov974","oP4RL8XHva","cBEJNZ6IEF"],
            cubroLoqued: ["cBEJNZ6IEF", "qLASdl2hrq", "CSokB0Fa5T", "djuMJVX9Rz", "sYpKN7ucH8", "judDlge9Gu", "rAzaBMB4W1",
                          "rvwKmOJ4z8", "v88j6oLq5L", "cvSv1gDFNO", "PNGw0EHO8G","XKc1tKjD0h", "oolTxA4CPg", "t16Gkqaadd",
                          "alzKp0U9Zb", "ALgDH1dbXo", "37kTMwNoTT", "xWgJs5Gw89", "lZdG7WGhke", "nMHyMTrgDP", "C4WQVQgu9m",
                          "HpjTYgDnvp", "ZppXwC9wah", "abUWKFByHi", "igh3uGimwW", "NIyaZf2leK", "Dji0HFOQQU", "qoPtKAP7ic",
                          "yG8XlJhsxT", "DZpfKGsk4f", "vtzUSvaLV0", "XjDO1qEH4P", "0gJXRpIjNp", "cin5awdTXO", 
                          "AWHeXNXHFZ", "xgob2fWgXF", "N9T0lRpUgl", "4NpnRr2DL7", "U9XQyScbgL","Od61kyJ4nb"
                        ],
            idealistaLoqued: ["cBEJNZ6IEF", "CSokB0Fa5T", "djuMJVX9Rz",  "judDlge9Gu", "rAzaBMB4W1","cin5awdTXO",
                              "v88j6oLq5L", "cvSv1gDFNO", "XKc1tKjD0h", "oolTxA4CPg", "t16Gkqaadd","F2O62Uf1ki",
                              "alzKp0U9Zb", "ALgDH1dbXo", "37kTMwNoTT", "xWgJs5Gw89", "lZdG7WGhke", "nMHyMTrgDP", "C4WQVQgu9m",
                              "HpjTYgDnvp", "ZppXwC9wah", "abUWKFByHi", "igh3uGimwW", "NIyaZf2leK", "Dji0HFOQQU", "qoPtKAP7ic",
                              "yG8XlJhsxT", "DZpfKGsk4f", "vtzUSvaLV0", "XjDO1qEH4P", "0gJXRpIjNp", "cin5awdTXO", "J00RKceYVH","vgLHBzIOeu",
                              "N9T0lRpUgl", "4NpnRr2DL7", "U9XQyScbgL","Od61kyJ4nb","OJTfDov974","oP4RL8XHva"
                            ],
            createLoqued:  [ "4NpnRr2DL7", "oP4RL8XHva", "OJTfDov974", "F2O62Uf1ki", "XjDO1qEH4P", "vtzUSvaLV0", "DZpfKGsk4f", "Dji0HFOQQU",
                             "qoPtKAP7ic", "NIyaZf2leK", "igh3uGimwW", "ZppXwC9wah", "HpjTYgDnvp", "C4WQVQgu9m", "lZdG7WGhke", "xWgJs5Gw89", "37kTMwNoTT",
                             "ALgDH1dbXo", "alzKp0U9Zb", "0gJXRpIjNp", "nMHyMTrgDP", "abUWKFByHi", "yG8XlJhsxT", "vgLHBzIOeu"],
            ciudadelaLoqued: [ "4NpnRr2DL7", "XjDO1qEH4P", "vtzUSvaLV0", "DZpfKGsk4f", "U9XQyScbgL","Od61kyJ4nb"],
            bauhausLoqued: ["cBEJNZ6IEF", "4NpnRr2DL7", "oP4RL8XHva", "OJTfDov974", "F2O62Uf1ki", "XjDO1qEH4P", "vtzUSvaLV0", "DZpfKGsk4f", "Dji0HFOQQU",
                            "qoPtKAP7ic", "NIyaZf2leK", "igh3uGimwW", "ZppXwC9wah", "HpjTYgDnvp", "C4WQVQgu9m", "lZdG7WGhke", "xWgJs5Gw89", "37kTMwNoTT",
                            "ALgDH1dbXo", "alzKp0U9Zb", "0gJXRpIjNp", "nMHyMTrgDP", "abUWKFByHi", "yG8XlJhsxT", "vgLHBzIOeu"],
            acentorLoqued: ["cBEJNZ6IEF", "4NpnRr2DL7", "oP4RL8XHva", "OJTfDov974", "F2O62Uf1ki", "XjDO1qEH4P", "vtzUSvaLV0", "DZpfKGsk4f", "Dji0HFOQQU",
                            "qoPtKAP7ic", "NIyaZf2leK", "igh3uGimwW", "ZppXwC9wah", "HpjTYgDnvp", "C4WQVQgu9m", "lZdG7WGhke", "xWgJs5Gw89", "37kTMwNoTT",
                            "ALgDH1dbXo", "alzKp0U9Zb", "0gJXRpIjNp", "nMHyMTrgDP", "abUWKFByHi", "yG8XlJhsxT", "OtiJWjLH6y", "rAzaBMB4W1", "U9XQyScbgL",
                            "vgLHBzIOeu","Od61kyJ4nb"],
            housellLoqued: ["cBEJNZ6IEF", "4NpnRr2DL7", "oP4RL8XHva", "OJTfDov974", "F2O62Uf1ki", "XjDO1qEH4P", "vtzUSvaLV0", "DZpfKGsk4f", "Dji0HFOQQU",
                            "qoPtKAP7ic", "NIyaZf2leK", "igh3uGimwW", "ZppXwC9wah", "HpjTYgDnvp", "C4WQVQgu9m", "lZdG7WGhke", "xWgJs5Gw89", "37kTMwNoTT",
                            "ALgDH1dbXo", "alzKp0U9Zb", "0gJXRpIjNp", "nMHyMTrgDP", "abUWKFByHi", "yG8XlJhsxT", "OtiJWjLH6y", "rAzaBMB4W1", "U9XQyScbgL",
                            "vgLHBzIOeu","Od61kyJ4nb"],
            faciliteaVerticals: ["cin5awdTXO", "oolTxA4CPg", "judDlge9Gu", "sYpKN7ucH8"],
            proManagerLoqued:["abUWKFByHi","igh3uGimwW","NIyaZf2leK","qoPtKAP7ic","Dji0HFOQQU","yG8XlJhsxT","DZpfKGsk4f","vtzUSvaLV0","XjDO1qEH4P","4NpnRr2DL7"
            ,"U9XQyScbgL","wrErlkbjuw","aKoILq1CeH","ZppXwC9wah","HpjTYgDnvp","C4WQVQgu9m","nMHyMTrgDP","lZdG7WGhke","xWgJs5Gw89","37kTMwNoTT","alzKp0U9Zb","ALgDH1dbXo"],
            serviceCopy:"",
            imageCopy:false,
            BusinessData:this.props.business
        };
    }

    loaded = dataFromChild => {
        this.setState(prevState => ({
            childrenLoaded: [...prevState.childrenLoaded, dataFromChild]
        }));
    };

    update = dataFromChild => {
        this.props.update(dataFromChild);
    };

    componentDidMount() {
        Jobin.Client.logOut().then();
        this.props.jobAvailability({ dateTo: null });
        this.props.jobAddress(null);
        let data = {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match
        }
        this.props.jobRouter(data);
        this._isMounted = true;
        const Business = Parse.Object.extend("Business");
        const businessId = getParameterByName("business");
        let servicePromise;
        if(businessId != null && businessId != "null" ){
            Promise.all([this.props.fetchBusinessData(businessId)]).then(([{business}])=>{
                this.setState({
                    BusinessData:business
                })
                this.isBusiness();
                
            }).catch(error=>{
                console.error("find business",error)
            })
        }
      if (this.props.checkAllowedBusiness(businessId) && !this.props.checkCustomVertical(businessId) ) { 
            servicePromise = new Parse.Query("Verticales")
                .equalTo("Active", true)
                .equalTo("Business",Business.createWithoutData(businessId))
                .ascending("Position")
                .limit(300)
                .find()
                
        } else { // Default
            servicePromise = new Parse.Query("Verticales")
                .doesNotExist("Father")
                .doesNotExist("Business")
                .equalTo("Active", true)
                .ascending("Position")
                .find();
        }

        servicePromise.then(
            result => {
                let imageCopyCustom = false
                if(this.state.isCubro){
                    result = result.filter( service => {
                        return !(this.state.cubroLoqued.some( (value) => value == service.id ))
                    })
                }
                if(this.state.isIdealista){
                    result = result.filter( service => {
                        return !(this.state.idealistaLoqued.some( (value) => value == service.id ))
                    })
                }
                
                if(this.state.isCiudadela){
                    result = result.filter( service => {
                        return !(this.state.ciudadelaLoqued.some( (value) => value == service.id ))
                    })
                }
                if(this.state.isFintonic){
                    result = result.filter( service => {
                        return !(this.state.fintonicLoqued.some( (value) => value == service.id ))
                    })
                }
                if(this.state.isMartha){
                    result = result.filter( service => {
                        return !(this.state.marthaLoqued.some( (value) => value == service.id ))
                    })
                }
                if(this.state.isGesvalt){
                    result = result.filter( service => {
                        return !(this.state.gesvaltLoqued.some( (value) => value == service.id ))
                    })
                }
                if(this.state.isAcentor){
                    result = result.filter( service => {
                        return !(this.state.acentorLoqued.some( (value) => value == service.id ))
                    })
                    imageCopyCustom ={ desk:require("../assets/AQ-FONDO.png"),mobile:require("../assets/AQ-FONDO.png") }
                }
                if(this.state.isDiglo){
                    result = result.filter( service => {
                        return !(this.state.digloLoqued.some( (value) => value == service.id ))
                    })
                    imageCopyCustom ={ desk:Diglo_banner_horizontal,mobile:Diglo_banner_vertical }
                }
                if(this.state.isUfesa){
                    imageCopyCustom ={ desk:Ufesa_banner_horizontal,
                                       mobile: Ufesa_banner_vertical}
                }
                if(this.state.isCreate ){
                    imageCopyCustom ={ desk:Create_banner_horizontal,
                                       mobile:Create_banner_vertical }
                }

                if(this.state.isHousell){
                    result = result.filter( service => {
                        return !(this.state.housellLoqued.some( (value) => value == service.id ))
                    })

                }

                if(this.state.isFacilitea){
                    result = result.filter( service => {
                        return (this.state.faciliteaVerticals.some( (value) => value == service.id ))
                    })
                }
                if(this.state.isProManager){
                    result = result.filter( service => {
                        return !(this.state.proManagerLoqued.some( (value) => value == service.id ))
                    })
                }

                if (this._isMounted) {
                    this.setState({
                        isLoaded: true,
                        items: result,
                        serviceCopy: "¿Qué servicio necesitas?",
                    });
                    if(imageCopyCustom){
                        this.setState({
                            imageCopy:{
                                mobile:imageCopyCustom.mobile,
                                desk:imageCopyCustom.desk
                            } ,
                        });
                    }
                }
            },
            error => {
                if (this._isMounted) {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            }
        );
        
        this.getCodeJustNow();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.BusinessData.id !== this.state.BusinessData.id)
            this.setState({
                BusinessData:this.props.business
        })
        let codeBussines =  getParameterByName("business")
        let deleteSome 
        switch(codeBussines){
            case 'ZcFC5W6QCB':
                deleteSome = 1
                break;
            case 'IH4E3M5mGW':
                deleteSome = 1
                break;
           
            case 'W8fBSZcVw5':
                deleteSome = 1
                break;
            case 'lMyRIzN79G':
                deleteSome = 1
                break;
            case 'HJkv3xWBGh':
                deleteSome = 1
                break;
            case 'BUFKRUAwbD':
                deleteSome = 1
                break;
            case "pB2BciWgw5":
                deleteSome = 1
                break;
            case "c8U8HpW6qb":
                deleteSome = 1
                break;
            case "ZF5WZAbvQ6":
                deleteSome = 1
                break;
            case "kqJtDU5D9b":
                deleteSome = 1
                break;
            case "kjy2Nmbl5v":
                deleteSome = 1
                break;
            case "mCdXO7Rjms":
                deleteSome = 1
            case "iopoWDLaeq":
                deleteSome = 1
                break;
            case "VzKtdQ6MwL":
                deleteSome = 1
                break;
            case "PEc3BfMCFs":
                deleteSome = 1
                break;
            case "lE4OT2K2Sa":
                deleteSome = 1
                break;
            default:
                deleteSome = 1
                break;
        }
        if (this.state.childrenLoaded.length === this.state.items.length - deleteSome)
            if (prevState.childrenLoaded !== this.state.childrenLoaded)
                this.setState({ isChildrenLoaded: true });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    isBusiness = () => {
        let codeBussines =  getParameterByName("business")
        localStorage.removeItem('Parse/ExxNZxyGaH3MmDYWIwKmg6okGr3VWySlxp4Dk16P/currentUser');

        switch(codeBussines.substring(0,10)){
            case 'lMyRIzN79G':
                this.props.isMutua(true);
                break;
            case 'IH4E3M5mGW':
                this.setState({ isIdealista: true });
                break;
            case 'HJkv3xWBGh':
                this.setState({ isCubro: true });
                break;
            case 'BUFKRUAwbD':
                this.setState({ isCiudadela: true });
                break;
            case "pB2BciWgw5":
                this.setState({ isFintonic: true });
                break;
            case "2uc4nQSWfG":
                this.setState({ isGesvalt: true });
                break;
            case "lE4OT2K2Sa":
                this.setState({ isMartha: true });
                break;
            case "ZcFC5W6QCB":
                this.setState({ isDiglo: true });
                break;
            case "c8U8HpW6qb":
                this.setState({ isCiudadela: true });
                break;
            case "W8fBSZcVw5":
                this.setState({ isPelayo: true });
                break;
            case "ZF5WZAbvQ6":
                this.setState({ isBauhaus: true });
                break;
            case "ZcFC5W6QCB":
                this.setState({ isDiglo: true });
                break;
            case "kqJtDU5D9b":
                this.setState({ isAcentor: true });
                break;
            case "kjy2Nmbl5v":
                this.setState({ isHousell: true });
                break;
            case "mCdXO7Rjms":
                this.setState({ isCreate: true });
                break;
            case "iopoWDLaeq":
                this.setState({ isUfesa: true });
                break;
            case "VzKtdQ6MwL":
                this.setState({ isWivai: true });
                break;
            case "PEc3BfMCFs":
                this.setState({ isFacilitea: true });
                break;
            default:
                if(this.state.BusinessData.isProManager){
                    this.setState({ isProManager: true });

                }
                localStorage.removeItem('Parse/ExxNZxyGaH3MmDYWIwKmg6okGr3VWySlxp4Dk16P/currentUser');
                break;
        }
    }


    getCodeJustNow = () => {
        let parameters = window.location.search.split('?');
        if(parameters.length > 1){
            let justnow = parameters[1].split('=');
            if(justnow[0] === 'justnow'){
                this.props.saveCodeJustNow(justnow[1]);
            }
        }
    }

    render() {
        const {
            serviceCopy,
            imageCopy,
            BusinessData

        } = this.state;
        const { error, isLoaded, isChildrenLoaded, items } = this.state;
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: cardSpinnerData.default,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        if (error) return <div>Error: {error.message}</div>;
        else {
            return (
                <div id="service-type">
                    <Nav
                        history={this.props.history}
                        location={this.props.location}
                        update={this.update}
                        mixpanel={this.props.mixpanel}
                    />
                    <div className="margin-top-72px">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <h2 className="title">
                                        {serviceCopy}
                                    </h2>
                                    {imageCopy ? (<img className="Business-banner-mobile" src={imageCopy.mobile}/>):null}
                                    {imageCopy ? (<img className="Business-banner" src={imageCopy.desk}/>):null}
                                    <div className="hidden-xs">
                                        <SearchService
                                            history={this.props.history}
                                            location={this.props.location}
                                            update={this.update}
                                            mixpanel={this.props.mixpanel}
                                        />
                                    </div>
                                    <div
                                        className={
                                            isChildrenLoaded
                                                ? "service-placeholder hidden"
                                                : "service-placeholder"
                                        }
                                    >
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Lottie
                                                    options={defaultOptions}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <Lottie
                                                    options={defaultOptions}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <Lottie
                                                    options={defaultOptions}
                                                />
                                            </div>
                                            <div className="col-sm-6">
                                                <Lottie
                                                    options={defaultOptions}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="services"
                                        className={
                                            isChildrenLoaded ? "" : "hidden"
                                        }
                                    >
                                        <div className="column-sm-6" style={{position:"relative"}}>
                                            {isLoaded && 
                                                items.map(service => {
                                                    return service.id ===
                                                        "8y4kFU8eiA" ||
                                                        service.id ===
                                                            "Z7HBY4VaNi" ? null : (
                                                        <ServiceItem
                                                            key={service.id}
                                                            service={service}
                                                            checkAllowedBusiness ={this.props.checkAllowedBusiness}
                                                            checkCustomVertical ={this.props.checkCustomVertical}
                                                            loaded={this.loaded}
                                                            BusinessData={BusinessData}
                                                            update={this.update}
                                                            mixpanel={
                                                                this.props
                                                                .mixpanel
                                                            }
                                                            history={
                                                                this.props
                                                                .history
                                                            }
                                                            location={
                                                                this.props
                                                                .location
                                                            }
                                                        />
                                                        );
                                                })
                                            }
                                        </div>
                                        <div className="col-md-7">
                                            <div className="row">
                                                {isLoaded && [
                                                    items.map(service => {
                                                        return service.id !==
                                                            "8y4kFU8eiA" &&
                                                            service.id !==
                                                                "Z7HBY4VaNi" ? null : (
                                                            <CustomServiceItem
                                                                key={service.id}
                                                                service={
                                                                    service
                                                                }
                                                                update={
                                                                    this.update
                                                                }
                                                                mixpanel={
                                                                    this.props
                                                                        .mixpanel
                                                                }
                                                                history={
                                                                    this.props
                                                                        .history
                                                                }
                                                                location={
                                                                    this.props
                                                                        .location
                                                                }
                                                            />
                                                        );
                                                    })
                                                ]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-lg-offset-1">
                                    <ServiceSteps />
                                </div>
                            </div>
                        </div>
                    </div>
                    <img
                        src="https://pixel.mathtag.com/event/img?mt_id=1443634&amp;mt_adid=230174&amp;mt_exem=&amp;mt_excl=&amp;v1=&amp;v2=&amp;v3=&amp;s1=&amp;s2=&amp;s3="
                        width="1"
                        height="1"
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToPros = dispatch => {
    return {
        saveCodeJustNow: (code) => dispatch(saveCodeJustNow(code)),
        isPelayo: (value) => dispatch(isPelayo(value)),
        isMutua: (value) => dispatch(isMutua(value)),
        jobRouter: (data) => dispatch(jobRouter(data)),
        jobAvailability: (data) => dispatch(jobAvailability(data)),
        jobDescription: (data) => dispatch(jobDescription(data)),
        jobAddress: (address) => dispatch(jobAddress(address))
    }
}

export default connect(mapStateToProps, mapDispatchToPros) (ServiceType);